// Set up routing so requests for files in /static are served from /node_modules/govuk-frontend/dist/govuk/all.
// import GDS components from `node_modules`
$govuk-images-path: "/static/images/";
$govuk-fonts-path: "/static/fonts/";
$govuk-new-typography-scale: true;
$govuk-global-styles: true;
$govuk-new-link-styles: true;
$gem-c-print-link-background-width: 16px;
$gem-c-print-link-background-height: 18px;

@import "node_modules/govuk-frontend/dist/govuk/all";

// import custom CSS
@import 'google-translate';

$laa-unknown-colour: govuk-colour("orange");
$laa-staging-colour: govuk-colour("turquoise");
$laa-dev-colour: govuk-colour("pink");

// overriding styles added in by google translate button, preventing footer not expanding.
// need to remove when we remove google translate button.
body {
  min-height: 0 !important;
}

.fala-tickbox-columns_new {
  // Default for mobile (1 column)
  column-count: 1;
  // Adjust the gap between columns
  column-gap: 16px;
}

@media only screen and (min-width: 730px) {
  .fala-tickbox-columns_new {
    // Default for mobile (1 column)
    column-count: 2;
  }
}

@media print {
  a[href]:after {
    content: none !important;
  }
  html, body, div, h2, h3, p, ul, li, span {
    font-size: 14px !important;
  }
}

.fala-tickbox-columns_new .govuk-checkboxes__item {
  // Ensure items do not break across columns
  break-inside: avoid;
}

.govuk-checkboxes__label {
  &::before {
    background-color: govuk-colour("white");
  }
}

.laa-cookie-policy {
  display: none;
  .js-enabled & {
    display: block;
  }
}

.fala-cookie-banner {
  display: none;
  .js-enabled & {
    display: block;
  }
}

.printing-button {
  display: none;
  .js-enabled & {
    display: block;
  }
}

body:not(.fala-production) {
  .govuk-header__container {
    border-bottom-color:$laa-unknown-colour;
  }
  .govuk-phase-banner__content__tag {
    background-color:$laa-unknown-colour
  }
}

body.fala-staging {
  .govuk-header__container {
    border-bottom-color:$laa-staging-colour;
  }
  .govuk-phase-banner__content__tag {
    background-color:$laa-staging-colour;
  }
}

body.fala-dev {
  .govuk-header__container {
    border-bottom-color:$laa-dev-colour;
  }
  .govuk-phase-banner__content__tag {
    background-color:$laa-dev-colour;
  }
}

.laa-fala {
  &__grey-box {
    background-color: govuk-colour("light-grey");
    padding: 1em;
  }
}

.results-list-item {
  margin-top: govuk-spacing(4);
  border-top: 1px solid govuk-colour("mid-grey");

  .telephone {
    margin-top: govuk-spacing(1);
    margin-bottom: govuk-spacing(2);
  }

  .address {
    margin-top: govuk-spacing(1);
    margin-bottom: 10px;
  }

  .website {
    margin-top: govuk-spacing(0);
    margin-bottom: govuk-spacing(2);
  }

  .categories {
    margin-top: govuk-spacing(2);
    margin-bottom: 0;

    .govuk-list {
      margin-bottom: 0;
    }
  }
}

.research-panel {
  background: govuk-colour("blue");

  text-align: left;

  // All text on the blue background should be white for accessibility and legibility purposes.
  // However, several govuk classes that provide handy spacing and typography rules also dictate a dark text colour.
  // Therefore we use this strong rule to ensure that text stays white even when we use those classes.
  * {
    color: white !important;
  }
}

.results-header {
  font-size: 19px;
  -webkit-font-smoothing: antialiased;
}

.print-link {
  background: url("icon-print.png") no-repeat govuk-spacing(2) 50%;
  background-size: $gem-c-print-link-background-width $gem-c-print-link-background-height;
  padding: govuk-spacing(2) govuk-spacing(2) govuk-spacing(2) (govuk-spacing(4) + $gem-c-print-link-background-width);
  text-decoration: none;
  border: 1px solid govuk-colour("dark-grey");
  color: $govuk-link-colour;
  cursor: pointer;
  margin: govuk-spacing(0);

  &:hover {
    background-color: govuk-colour("light-grey");
  }

  &:focus {
    @include govuk-focused-text;
    background-color: $govuk-focus-colour;
    border-color: transparent;
  }
}

.hidden {
  display: none;
}

.hint-colour-override {
  color: $govuk-text-colour;
}

.language-switcher {
  text-align: right;
  margin-right: govuk-spacing(0);
}

.language-and-back-container{
  display: flex;
  justify-content: space-between; 
  align-items: center;
}